








import { Component, Vue } from 'vue-property-decorator';
import Amplify from '@aws-amplify/core'

const NEW_AUTH_URL = `https://${process.env.VUE_APP_AUTH_DOMAIN}/login?redirect_uri=${process.env.VUE_APP_INNERSPACE_URL}&response_type=code&client_id=${process.env.VUE_APP_COGNITO_CLIENT_ID}`;

@Component
export default class SignIn extends Vue {
  launch(): void {
    const params = new URLSearchParams(window.location.search);
    const securityUser = params.has("securityUser");
    if(securityUser) {
      setTimeout(() => Amplify.Auth.federatedSignIn(), 500); // Hack please close eyes
    } else {
      window.location.href = NEW_AUTH_URL;
    }   
  }
}
